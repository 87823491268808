import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
  Asset,
  AssetFileSize,
  MessageType,
  Model,
  Notification,
  Project,
} from '@verify/shared-components/models';
import { StorageService } from '@verify/shared-components/services';
import { Observable, switchMap, take } from 'rxjs';
import { ModelNamePipe } from '../../../pipes';
import { AssetService, ModelService, ProjectService } from '../../../services';

@Component({
  selector: 'app-notification',
  standalone: true,
  imports: [CommonModule, ModelNamePipe, MatIcon, TranslateModule],
  templateUrl: './notification.component.html',
  styleUrl: './notification.component.scss',
})
export class NotificationComponent implements OnInit {
  private router = inject(Router);
  private projectService = inject(ProjectService);
  private modelService = inject(ModelService);
  private assetService = inject(AssetService);
  private storageService = inject(StorageService);

  project$: Observable<Project>;
  model$: Observable<Model>;
  asset$: Observable<Asset>;
  imageUrl$?: Observable<string>;

  @Input({ required: true })
  notification: Notification;

  @Output()
  closeNotification = new EventEmitter<void>();

  MessageType = MessageType;

  ngOnInit(): void {
    if (this.notification.variables['projectId']) {
      this.project$ = this.projectService.getProject(
        this.notification.variables['projectId'],
      );
    }
    if (this.notification.variables['modelId']) {
      this.model$ = this.modelService.getModel(
        this.notification.variables['modelId'],
      );
    }
    if (this.notification.variables['assetId']) {
      this.asset$ = this.assetService.getAsset(
        this.notification.variables['assetId'],
      );
      this.imageUrl$ = this.asset$.pipe(
        switchMap((asset) => {
          const file =
            asset.files?.find((file) => file.size == AssetFileSize.size300) ||
            asset.files?.[0];
          return this.storageService.getDownloadUrl(file.filePath, true);
        }),
      );
    }
  }

  @HostListener('click') onClick() {
    if (this.notification.type === MessageType.modelRevokeConsent) {
      this.router.navigate(
        ['project', this.notification.variables['projectId']],
        { queryParams: { modelId: this.notification.variables['modelId'] } },
      );
    } else if (this.notification.type === MessageType.assetFingerprintFailed) {
      this.asset$.pipe(take(1)).subscribe((asset) => {
        this.router.navigate(['project', asset.projectId]);
      });
    }
    this.closeNotification.emit();
  }
}
