import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  Asset,
  Model,
  ModelConsent,
  ModelConsentStatus,
  Project,
  UserRole,
} from '@verify/shared-components/models';
import { DialogService, HasRolePipe } from '@verify/shared-components/services';
import { Observable, Subject, takeUntil } from 'rxjs';
import { ModelNamePipe } from '../../pipes';
import { ModelService } from '../../services';
import { ModelConsentService } from '../../services/model-consent.service';
import { AssetTileComponent } from '../shared/asset-tile/asset-tile.component';
import { ContractDialogComponent } from '../shared/contract-dialog/contract-dialog.component';
import { HeaderComponent } from '../shared/header/header.component';
import { ModelTileComponent } from '../shared/model-tile/model-tile.component';

@Component({
  selector: 'app-search',
  standalone: true,
  imports: [
    CommonModule,
    AssetTileComponent,
    ModelTileComponent,
    MatExpansionModule,
    MatIconModule,
    MatButtonModule,
    ModelNamePipe,
    TranslateModule,
    HeaderComponent,
    MatTooltipModule,
    HasRolePipe,
  ],
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss',
})
export class SearchComponent implements OnInit, OnDestroy {
  private activatedRoute = inject(ActivatedRoute);
  private modelService = inject(ModelService);
  private modelConsentService = inject(ModelConsentService);
  private router = inject(Router);
  private dialogService = inject(DialogService);
  private snackBar = inject(MatSnackBar);
  private translateService = inject(TranslateService);

  private destroy$ = new Subject<void>();

  //assets$?: Observable<Asset[]>;
  models$?: Observable<Model[]>;

  projectModelConsents$?: Observable<
    Array<{ project?: Project; modelConsent: ModelConsent }>
  >;
  assets$?: Observable<Asset[]>;

  UserRole = UserRole;
  ModelConsentStatus = ModelConsentStatus;

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ query }) => {
        //this.assets$ = this.assetService.searchAssets(query);
        this.models$ = this.modelService.searchModels(query);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onOpenModel(model: Model): void {
    this.projectModelConsents$ = this.modelConsentService.getProjectsAndConsent(
      model.id,
    );
  }

  onOpenAsset(asset: Asset): void {
    this.router.navigate(['asset', asset.id]);
  }

  onOpenContract(
    project: Project,
    modelConsent: ModelConsent,
    model: Model,
  ): void {
    this.dialogService.openDialog(ContractDialogComponent, {
      data: {
        model: model,
        modelConsent: modelConsent,
        project: project,
      },
      width: this.dialogService.widths.mediumLarge,
    });
  }

  onRevokeModelConsent(modelConsent: ModelConsent): void {
    this.modelConsentService.revokeModelConsent(modelConsent);
  }

  onUnrevokeModelConsent(modelConsent: ModelConsent): void {
    this.modelConsentService.unrevokeModelConsent(modelConsent);
  }

  onSendReminder(modelConsent: ModelConsent): void {
    this.modelConsentService.resendEmail(modelConsent).subscribe(() => {
      this.snackBar.open(
        this.translateService.instant('model.reminder-sent'),
        null,
        { duration: 5000 },
      );
    });
  }

  onGoToProject(project: Project, model: Model) {
    this.router.navigate(['project', project.id], {
      queryParams: { modelId: model.id },
    });
  }

  trackById(_: number, { id }: { id: string }): string {
    return id;
  }

  trackByProjectId(
    _: number,
    { project }: { project?: Project; modelConsent: ModelConsent },
  ): string {
    return project?.id;
  }
}
