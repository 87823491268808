import { CommonModule, DatePipe } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute } from '@angular/router';
import {
  ClientTimestamp,
  CustomField,
  GetModelRegistrationInfoResponse,
  SaveModelRegistrationRequest,
} from '@verify/shared-components/models';

import { Timestamp } from '@angular/fire/firestore';
import {
  MatButtonToggleChange,
  MatButtonToggleModule,
} from '@angular/material/button-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SpinnerComponent } from '@verify/shared-components/components';
import { AuthService, TimestampPipe } from '@verify/shared-components/services';

import { enrichedFormText } from '../../../helpers';
import { ModelService } from '../../../services';

@Component({
  selector: 'app-register-model',
  standalone: true,
  imports: [
    CommonModule,
    SpinnerComponent,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    MatSelectModule,
    TranslateModule,
  ],
  providers: [TimestampPipe, DatePipe],
  templateUrl: './register-model.component.html',
  styleUrl: './register-model.component.scss',
})
export class RegisterModelComponent implements OnInit {
  private activatedRoute = inject(ActivatedRoute);
  private modelService = inject(ModelService);
  private authService = inject(AuthService);
  private formBuilder = inject(NonNullableFormBuilder);
  private sanitizer = inject(DomSanitizer);
  private translateService = inject(TranslateService);
  private timestampPipe = inject(TimestampPipe);
  private datePipe = inject(DatePipe);

  modelRegistrationInfo?: GetModelRegistrationInfoResponse;

  form: FormGroup = this.formBuilder.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    customData: new FormGroup([]),
  });
  selfie?: File;
  selfiePreview?: string;
  selfieDataUrl?: string | ArrayBuffer;
  selectedVariant?: string;
  signing?: boolean;
  signed?: boolean;
  filteredCustomFields?: CustomField[];

  @ViewChild('formElement') formElement: ElementRef<HTMLDivElement>;

  ngOnInit(): void {
    const { projectId, formTemplateId, modelConsentId } =
      this.activatedRoute.snapshot.params;
    const tenantId = this.authService.getAuth().tenantId;
    (modelConsentId
      ? this.modelService.getModelRegistrationInfoFromInvite(
          tenantId,
          modelConsentId,
        )
      : this.modelService.getModelRegistrationInfo(
          tenantId,
          projectId,
          formTemplateId,
        )
    ).subscribe((modelRegistrationInfo) => {
      this.modelRegistrationInfo = modelRegistrationInfo;
      this.setVariant(
        modelRegistrationInfo.formTemplate?.variants?.[0].language,
      );
      if (modelRegistrationInfo.model) {
        const { firstName, lastName, email } = modelRegistrationInfo.model;
        this.form.patchValue({ firstName, lastName, email });
      }
    });
  }

  get formText(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      enrichedFormText(
        this.modelRegistrationInfo?.formTemplate?.variants?.find(
          (variant) => variant.language === this.selectedVariant,
        )?.text,
        {
          projectName: this.modelRegistrationInfo.name,
          projectDescription: this.modelRegistrationInfo.description,
          projectPhotoshootDate: this.modelRegistrationInfo.shootDate
            ? this.timestampPipe.transform(
                this.modelRegistrationInfo.shootDate as ClientTimestamp,
                'mediumDate',
              )
            : '',
          modelFirstName: this.form.get('firstName').value,
          modelLastName: this.form.get('lastName').value,
          modelEmail: this.form.get('email').value,
          signedDate: Timestamp.now(),
          ...(this.modelRegistrationInfo.customData || []).reduce(
            (acc, data) => ({
              ...acc,
              [`project{${data.name}}`]:
                typeof data.value === 'object' && '_seconds' in data.value
                  ? this.timestampPipe.transform(
                      data.value as ClientTimestamp,
                      'mediumDate',
                    )
                  : data.value,
            }),
            {},
          ),
          ...(Object.entries(this.form.get('customData').value) || []).reduce(
            (acc, [name, value]) => ({
              ...acc,
              [`model{${name}}`]:
                value instanceof Date
                  ? this.datePipe.transform(value as Date)
                  : value,
            }),
            {},
          ),
        },
      ),
    );
  }
  // get formText2(): string {
  //   return enrichedFormText(
  //     this.modelRegistrationInfo?.formTemplate?.variants?.find(
  //       (variant) => variant.language === this.selectedVariant,
  //     )?.text,
  //     {
  //       projectName: this.modelRegistrationInfo.name,
  //       projectDescription: this.modelRegistrationInfo.description,
  //       projectPhotoshootDate: this.modelRegistrationInfo.shootDate,
  //       modelFirstName: this.form.get('firstName').value,
  //       modelLastName: this.form.get('lastName').value,
  //       modelEmail: this.form.get('email').value,
  //       signDate: Timestamp.now(),
  //     },
  //   );
  // }

  onSign(): void {
    if (this.selfie && !this.signing) {
      const projectId = this.modelRegistrationInfo.projectId;
      const formTemplateId = this.modelRegistrationInfo.formTemplate.id;
      const tenantId = this.authService.getAuth().tenantId;
      this.signing = true;
      this.modelService
        .saveModelRegistration(
          {
            tenantId,
            projectId,
            formTemplateId,
            formVariantId: this.selectedVariant,
            modelConsentId:
              this.activatedRoute.snapshot.params['modelConsentId'],
            formHtml: this.formElement.nativeElement.innerHTML,
            ...(this.form.value as SaveModelRegistrationRequest),
            customData: Object.entries(this.form.value.customData).map(
              ([name, value]) => ({
                name,
                value: (value instanceof Date
                  ? Timestamp.fromDate(value)
                  : value) as string | number | ClientTimestamp,
              }),
            ),
          },
          this.selfie,
        )
        .subscribe(() => {
          this.signed = true;
        });
    }
  }

  onSelectSelfie(event: unknown): void {
    const files = (event as { target: { files: FileList } }).target.files;
    if (files.length > 0) {
      this.selfie = files[0];
      this.selfiePreview = URL.createObjectURL(this.selfie);

      //resize the image for display on the contract
      const reader = new FileReader();
      reader.onload = () => {
        const img = document.createElement('img');
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const ratio = img.width / img.height;
          if (ratio < 0) {
            canvas.width = 300;
            canvas.height = 300 / ratio;
            ctx.drawImage(img, 0, 0, 300, 300 / ratio);
          } else {
            canvas.width = 300 * ratio;
            canvas.height = 300;
            ctx.drawImage(img, 0, 0, 300 * ratio, 300);
          }
          this.selfieDataUrl = canvas.toDataURL();
        };
        img.src = reader.result as string;
      };
      reader.readAsDataURL(this.selfie);
    }
  }

  onChangeVariant(event: MatButtonToggleChange): void {
    this.setVariant(event.value);
  }

  @HostListener('window:beforeunload', ['$event'])
  handleClose(e: BeforeUnloadEvent): void {
    if (this.signing && !this.signed) {
      e.preventDefault();
    }
  }

  private setVariant(variantId: string): void {
    this.selectedVariant = variantId;
    if (this.translateService.currentLang !== variantId.toLocaleLowerCase()) {
      this.translateService.use(variantId.toLocaleLowerCase());
    }
    this.filterCustomFields();
  }

  private filterCustomFields(): void {
    const text =
      this.modelRegistrationInfo?.formTemplate?.variants?.find(
        (variant) => variant.language === this.selectedVariant,
      )?.text || '';

    this.filteredCustomFields = (
      this.modelRegistrationInfo.customModelFields || []
    ).filter((customField) => text.includes(`{{model{${customField.name}}}`));

    const customDataGroup = this.form.get('customData') as FormGroup;
    for (const [key] of Object.entries(customDataGroup.controls)) {
      if (
        !this.filteredCustomFields.some(
          (customField) => customField.name === key,
        )
      ) {
        customDataGroup.removeControl(key);
      }
    }
    for (const customField of this.filteredCustomFields || []) {
      if (!customDataGroup.get(customField.name)) {
        customDataGroup.addControl(
          customField.name,
          new FormControl(
            '',
            customField.required ? [Validators.required] : [],
          ),
        );
      }
    }
  }
}
