import { map, Observable, take } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import {
  arrayRemove,
  arrayUnion,
  orderBy,
  Timestamp,
  where,
} from '@angular/fire/firestore';
import {
  CollectionName,
  FunctionName,
  StoragePath,
} from '@verify/shared-components/helpers';
import {
  Asset,
  AssetFace,
  AssetStatus,
  DeleteItemsRequest,
  FaceAiFaceSuggestionsRequest,
  FaceAiFaceSuggestionsResponse,
  FaceAiTagAssetsRequest,
  ModelTag,
  SendImagesToBynderRequest,
  SendImagesToBynderResponse,
  SendImagesToLythoRequest,
  SendImagesToLythoResponse,
} from '@verify/shared-components/models';
import {
  AuthService,
  FirestoreService,
  FunctionService,
  StorageService,
} from '@verify/shared-components/services';

@Injectable({
  providedIn: 'root',
})
export class AssetService {
  private firestore = inject(FirestoreService);
  private storageService = inject(StorageService);
  private functionService = inject(FunctionService);
  private authService = inject(AuthService);

  constructor() {}

  getAsset(assetId: string): Observable<Asset | undefined> {
    return this.firestore.getDocument<Asset>(
      `${CollectionName.assets}/${assetId}`,
    );
  }

  getAssets({
    projectId,
    modelId,
    order,
  }: {
    projectId: string;
    modelId?: string;
    order?: { field: string; direction: 'asc' | 'desc' };
  }): Observable<Asset[]> {
    const filters = [
      where('deleted', '!=', true),
      ...(projectId ? [where('projectId', '==', projectId)] : []),
      ...(modelId ? [where('modelIds', 'array-contains', modelId)] : []),
      ...(order
        ? [orderBy(order.field, order.direction)]
        : [orderBy('creationDate', 'asc')]),
    ];
    return this.firestore.getQuery<Asset>(CollectionName.assets, ...filters);
  }

  searchAssets(query: string): Observable<Asset[]> {
    return this.firestore
      .getCollection<Asset>(CollectionName.assets)
      .pipe(
        map((assets) =>
          assets.filter((asset) => asset.name.indexOf(query) > -1),
        ),
      );
  }

  addAsset(
    projectId: string,
    asset: Partial<Asset>,
    file: File,
  ): Observable<Asset | undefined> {
    const asset$ = this.firestore.addDocument<Asset>(
      `${CollectionName.assets}`,
      {
        ...asset,
        projectId,
        fingerprintId: null,
        status: AssetStatus.uploading,
        name: file.name,
        size: file.size,
        type: file.type,
        createdBy: this.authService.currentUser.id,
        creationDate: Timestamp.now(),
        modificationDate: Timestamp.now(),
        deleted: false,
        online: false,
        modelConsentStatus: null,
      },
    );

    asset$.pipe(take(1)).subscribe((asset) => {
      if (asset) {
        this.uploadFile(file, projectId, asset.id);
      }
    });

    return asset$;
  }

  addModelTag(assetId: string, modelTag: ModelTag): void {
    this.getAsset(assetId)
      .pipe(take(1))
      .subscribe((asset) => {
        if (!asset.modelIds?.includes(modelTag.modelId)) {
          this.firestore.updateDocument<Asset>(
            `${CollectionName.assets}/${assetId}`,
            {
              modelTags: arrayUnion(modelTag),
              modelIds: arrayUnion(modelTag.modelId),
              modificationDate: Timestamp.now(),
            },
          );
        }
      });
  }

  removeModelTag(assetId: string, modelTag: ModelTag): void {
    this.getAsset(assetId)
      .pipe(take(1))
      .subscribe((asset) => {
        if (asset.modelIds?.includes(modelTag.modelId)) {
          this.firestore.updateDocument<Asset>(
            `${CollectionName.assets}/${assetId}`,
            {
              modelTags: arrayRemove(
                asset.modelTags.find(
                  (assetModelTag) => assetModelTag.modelId === modelTag.modelId,
                ),
              ),
              modelIds: arrayRemove(modelTag.modelId),
              modificationDate: Timestamp.now(),
            },
          );
        }
      });
  }

  deleteAsset(assetId: string): void {
    this.firestore.updateDocument<Asset>(
      `${CollectionName.assets}/${assetId}`,
      {
        deleted: true,
        modificationDate: Timestamp.now(),
      },
    );
  }

  undoDeleteAsset(assetId: string): void {
    this.firestore.updateDocument<Asset>(
      `${CollectionName.assets}/${assetId}`,
      {
        deleted: false,
        modificationDate: Timestamp.now(),
      },
    );
  }

  permanentlyDeleteAssets(assetIds: string[]): Observable<void> {
    return this.functionService.call<DeleteItemsRequest, void>(
      FunctionName.deleteItems,
      { itemIds: assetIds, type: 'assets' },
      { timeout: 600000 },
    );
  }

  getDeletedAssets(): Observable<Asset[]> {
    const filters = [
      where('deleted', '==', true),
      orderBy('modificationDate', 'desc'),
    ];
    return this.firestore.getQuery<Asset>(CollectionName.assets, ...filters);
  }

  autoTagAssets(projectId: string, assetIds: string[]): Observable<void> {
    return this.functionService.call<FaceAiTagAssetsRequest, void>(
      FunctionName.faceAiTagAssets,
      { projectId, assetIds },
      { timeout: 600000 },
    );
  }

  getSuggestions(
    projectId: string,
    face: AssetFace,
  ): Observable<FaceAiFaceSuggestionsResponse> {
    return this.functionService.call<
      FaceAiFaceSuggestionsRequest,
      FaceAiFaceSuggestionsResponse
    >(FunctionName.getSuggestions, { projectId, face });
  }

  exportAssetsToLytho(
    projectId: string,
    assetIds: string[],
  ): Observable<SendImagesToLythoResponse> {
    return this.functionService.call<
      SendImagesToLythoRequest,
      SendImagesToLythoResponse
    >(
      FunctionName.sendImagesToLytho,
      { projectId, assetIds },
      { timeout: 600000 },
    );
  }

  exportAssetsToBynder(
    projectId: string,
    assetIds: string[],
  ): Observable<SendImagesToBynderResponse> {
    return this.functionService.call<
      SendImagesToBynderRequest,
      SendImagesToBynderResponse
    >(
      FunctionName.sendImagesToBynder,
      { projectId, assetIds },
      { timeout: 600000 },
    );
  }

  updateAssetMatches(assetId: string): Observable<void> {
    return this.functionService.call<{ assetId: string }, void>(
      FunctionName.callMonitoringResults,
      { assetId },
    );
  }

  getNextAsset(projectId: string, assetId: string): Observable<Asset> {
    return this.getAssets({ projectId }).pipe(
      map((assets) => {
        const index = assets.findIndex((asset) => asset.id === assetId);
        return assets[index + 1 < assets.length ? index + 1 : 0];
      }),
    );
  }

  getPreviousAsset(projectId: string, assetId: string): Observable<Asset> {
    return this.getAssets({ projectId }).pipe(
      map((assets) => {
        const index = assets.findIndex((asset) => asset.id === assetId);
        return assets[index > 0 ? index - 1 : assets.length - 1];
      }),
    );
  }

  private uploadFile(file: File, projectId: string, assetId: string): void {
    this.storageService.uploadFile(
      `${StoragePath.projects}/${projectId}/${StoragePath.assets}/${assetId}/${file.name}`,
      file,
      assetId,
    );
  }
}
