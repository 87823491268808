import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import {
  Asset,
  Model,
  ModelConsent,
  ModelConsentStatus,
  Project,
  UserRole,
} from '@verify/shared-components/models';
import { DialogService, HasRolePipe } from '@verify/shared-components/services';
import { ModelNamePipe } from '../../../../pipes';
import { ContractDialogComponent } from '../../contract-dialog/contract-dialog.component';
import { ModelTileComponent } from '../../model-tile/model-tile.component';

@Component({
  selector: 'app-model-info',
  standalone: true,
  imports: [
    CommonModule,
    ModelNamePipe,
    ModelTileComponent,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    TranslateModule,
    HasRolePipe,
    MatMenuModule,
  ],
  templateUrl: './model-info.component.html',
  styleUrl: './model-info.component.scss',
})
export class ModelInfoComponent {
  private dialogService = inject(DialogService);

  @Input({ required: true })
  model: Model;
  @Input()
  project?: Project;
  @Input()
  assets?: Asset[];
  @Input()
  modelConsent?: ModelConsent;
  @Input()
  cancelOption?: boolean;

  @Output()
  removeModelConsent = new EventEmitter<ModelConsent>();
  @Output()
  selectModel = new EventEmitter<Model>();
  @Output()
  revokeModelConsent = new EventEmitter<ModelConsent>();
  @Output()
  unrevokeModelConsent = new EventEmitter<ModelConsent>();
  @Output()
  sendReminder = new EventEmitter<ModelConsent>();

  UserRole = UserRole;
  ModelConsentStatus = ModelConsentStatus;

  onRemove(): void {
    this.removeModelConsent.emit(this.modelConsent);
  }

  onOpenContract(): void {
    this.dialogService.openDialog(ContractDialogComponent, {
      data: {
        model: this.model,
        modelConsent: this.modelConsent,
        project: this.project,
      },
      width: this.dialogService.widths.mediumLarge,
    });
  }

  canRemove(modelConsent: ModelConsent): boolean {
    return (
      this.cancelOption ||
      ((modelConsent.status === ModelConsentStatus.pending ||
        modelConsent.generic) &&
        !this.assets.length)
    );
  }

  get matchCount(): number {
    return (this.assets || []).reduce(
      (acc, asset) => acc + asset.matches.length,
      0,
    );
  }

  onSelectModel(): void {
    this.selectModel.emit(this.model);
  }

  onRevoke(): void {
    this.revokeModelConsent.emit(this.modelConsent);
  }

  onUnrevoke(): void {
    this.unrevokeModelConsent.emit(this.modelConsent);
  }

  onSendReminder(): void {
    this.sendReminder.emit(this.modelConsent);
  }
}
