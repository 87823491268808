import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import {
  Asset,
  AssetFileSize,
  Model,
  ModelConsent,
  Project,
  UserRole,
} from '@verify/shared-components/models';
import {
  HasRolePipe,
  StorageService,
} from '@verify/shared-components/services';
import { combineLatest, map, Observable, of, switchMap } from 'rxjs';
import { AssetService, ModelService } from '../../../services';
import { ModelConsentService } from '../../../services/model-consent.service';
import { ModelTileComponent } from '../../shared/model-tile/model-tile.component';

@Component({
  selector: 'app-project-tile',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    HasRolePipe,
    ModelTileComponent,
    TranslateModule,
    MatIconModule,
  ],
  templateUrl: './project-tile.component.html',
  styleUrl: './project-tile.component.scss',
})
export class ProjectTileComponent implements OnChanges {
  private storageService = inject(StorageService);
  private modelConsentService = inject(ModelConsentService);
  private modelService = inject(ModelService);
  private assetService = inject(AssetService);

  @Input({ required: true })
  project!: Project;

  @Output()
  deleteProject = new EventEmitter<Project>();
  @Output()
  openProject = new EventEmitter<Project>();

  imageUrls$?: Observable<string[]>;
  modelConsent$?: Observable<
    Array<{ model?: Model; modelConsent: ModelConsent }> | undefined
  >;
  models$?: Observable<Model[]>;
  assets$?: Observable<Asset[]>;
  matchesFound$?: Observable<number>;

  UserRole = UserRole;

  ngOnChanges(): void {
    if (this.project && !this.models$) {
      this.models$ = this.modelService.getModelsByProject(this.project.id);
      this.assets$ = this.assetService.getAssets({
        projectId: this.project.id,
      });
      this.matchesFound$ = this.assets$.pipe(
        map((assets) =>
          assets.reduce(
            (total, asset) => total + (asset.matches?.length || 0),
            0,
          ),
        ),
      );
      this.imageUrls$ = this.assets$.pipe(
        switchMap((assets) =>
          combineLatest(
            (assets || []).slice(0, 3).map((asset, index) => {
              const file =
                asset.files?.find(
                  (file) =>
                    file.size ===
                    (index === 0
                      ? AssetFileSize.size1000
                      : AssetFileSize.size300),
                ) || this.project.banner?.[0];
              return file
                ? this.storageService.getDownloadUrl(file.filePath, true)
                : of('');
            }),
          ),
        ),
      );
    }
  }

  onDeleteProject(): void {
    this.deleteProject.emit(this.project);
  }

  onOpenProject(): void {
    this.openProject.emit(this.project);
  }

  trackByModelConsent(
    _: number,
    modelConsent: { modelConsent: ModelConsent },
  ): string {
    return modelConsent.modelConsent.modelId;
  }

  trackByAsset(_: number, asset: Asset): string {
    return asset.id;
  }
}
