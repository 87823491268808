<app-header [title]="'project.deleted-projects' | translate"></app-header>

<div class="item-list">
  <div class="item-list-toolbar">
    <mat-form-field subscriptSizing="dynamic" class="item-list-search">
      <mat-label>{{ "search.filter" | translate }}</mat-label>
      <input
        matInput
        #projectSearchInput
        type="text"
        (keyup)="onFilterProjects(projectSearchInput.value)"
      />
    </mat-form-field>
  </div>
  <table mat-table [dataSource]="projectsDataSource" class="contained-table">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>
        {{ "project.name" | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="deleted">
      <th mat-header-cell *matHeaderCellDef>
        {{ "base.deleted" | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.modificationDate | timestamp: "medium" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell class="field-actions" *matCellDef="let element">
        <button mat-button (click)="onUndoDeleteProject(element.id)">
          {{ "base.undo-delete" | translate }}
        </button>
        <button mat-button (click)="onDeleteProject(element.id)">
          {{ "base.remove" | translate }}
        </button>
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="displayedColumns"
      class="contained-table-header"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      class="contained-table-row"
    ></tr>
  </table>
  <mat-paginator
    #projectPaginator
    hidePageSize="true"
    pageSize="10"
    showFirstLastButtons
  >
  </mat-paginator>
</div>

<app-header [title]="'asset.deleted-assets' | translate"></app-header>

<div class="item-list">
  <div class="item-list-toolbar">
    <mat-form-field subscriptSizing="dynamic" class="item-list-search">
      <mat-label>{{ "search.filter" | translate }}</mat-label>
      <input
        matInput
        #assetSearchInput
        type="text"
        (keyup)="onFilterAssets(assetSearchInput.value)"
      />
    </mat-form-field>
  </div>
  <table mat-table [dataSource]="assetsDataSource" class="bordered-table">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>
        {{ "asset.name" | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="deleted">
      <th mat-header-cell *matHeaderCellDef>
        {{ "base.deleted" | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.modificationDate | timestamp: "medium" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell class="field-actions" *matCellDef="let element">
        <button mat-button (click)="onUndoDeleteAsset(element.id)">
          {{ "base.undo-delete" | translate }}
        </button>
        <button mat-button (click)="onDeleteAsset(element.id)">
          {{ "base.remove" | translate }}
        </button>
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="displayedColumns"
      class="contained-table-header"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      class="contained-table-row"
    ></tr>
  </table>
  <mat-paginator
    #assetPaginator
    hidePageSize="true"
    pageSize="10"
    showFirstLastButtons
  >
  </mat-paginator>
</div>
