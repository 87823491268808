<!-- <div class="container">
  <div class="assets">
    <app-asset-tile
      *ngFor="let asset of assets$ | async; trackBy: trackByAsset"
      [asset]="asset"
      (openAsset)="onOpenAsset($event)"
    ></app-asset-tile>
  </div>
</div> -->

<app-header [title]="'model.models' | translate"></app-header>

<p *ngIf="!(models$ | async)?.length">{{ "search.no-results" | translate }}</p>

<mat-accordion>
  <mat-expansion-panel
    class="model-panel mat-elevation-z0"
    *ngFor="let model of models$ | async; trackBy: trackById"
    (opened)="onOpenModel(model)"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <app-model-tile class="model-tile" [model]="model" size="medium">
        </app-model-tile>
        {{ model | modelName }}
      </mat-panel-title>
      <mat-panel-description> </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <div
        class="model-project"
        *ngFor="
          let projectModelConsent of projectModelConsents$ | async;
          trackBy: trackByProjectId
        "
      >
        <div class="model-project-title">
          <h6>{{ projectModelConsent.project.name }}</h6>
        </div>
        <div class="status-tag-dark">
          <mat-icon
            [class]="
              'status-tag-icon status-tag-icon-' +
              projectModelConsent.modelConsent.status.toLowerCase()
            "
            *ngIf="
              projectModelConsent.modelConsent.status !==
              ModelConsentStatus.signed
            "
            >error</mat-icon
          >
          <span>{{
            "model.status-" +
              projectModelConsent.modelConsent.status.toLowerCase() | translate
          }}</span>
        </div>
        <ng-container *ngIf="UserRole.projectManagement | hasRole">
          <ng-container [ngSwitch]="projectModelConsent.modelConsent.status">
            <button
              *ngSwitchCase="ModelConsentStatus.signed"
              class="model-info-large"
              mat-icon-button
              color="primary"
              [matTooltip]="'model.revoke-consent' | translate"
              (click)="onRevokeModelConsent(projectModelConsent.modelConsent)"
            >
              <mat-icon>contract_delete</mat-icon>
            </button>
            <button
              *ngSwitchCase="ModelConsentStatus.revoked"
              class="model-info-large"
              mat-icon-button
              color="primary"
              [matTooltip]="'model.unrevoke-consent' | translate"
              (click)="onUnrevokeModelConsent(projectModelConsent.modelConsent)"
            >
              <mat-icon>contract_edit</mat-icon>
            </button>
            <button
              *ngSwitchDefault
              class="model-info-large"
              mat-icon-button
              color="primary"
              [matTooltip]="'model.send-reminder' | translate"
              (click)="onSendReminder(projectModelConsent.modelConsent)"
            >
              <mat-icon>forward_to_inbox</mat-icon>
            </button>
          </ng-container>
        </ng-container>
        <button
          mat-stroked-button
          color="primary"
          (click)="
            onOpenContract(
              projectModelConsent.project,
              projectModelConsent.modelConsent,
              model
            )
          "
        >
          <mat-icon>search</mat-icon>
          {{ "model.contract" | translate }}
        </button>
        <button
          mat-stroked-button
          color="primary"
          (click)="onGoToProject(projectModelConsent.project, model)"
        >
          <mat-icon>arrow_forward</mat-icon>
          {{ "project.to-project" | translate }}
        </button>
      </div>
    </ng-template>
  </mat-expansion-panel>
</mat-accordion>
