<div class="model-list-header">
  <div class="model-list-header-title">
    <h6>Models</h6>
    <p class="text-body-2">
      {{ modelsData?.length }} {{ "base.total" | translate }} -
      {{ statusCounts.signed }} {{ "model.status-signed" | translate }} -
      {{ statusCounts.pending }} {{ "model.status-pending" | translate }} -
      {{ statusCounts.revoked }}
      {{ "model.status-revoked" | translate }}
    </p>
  </div>
  <mat-button-toggle-group
    class="model-list-header-status"
    name="status"
    [value]="selectedStatus"
    (change)="onChangeStatus($event)"
    hideSingleSelectionIndicator="true"
  >
    <mat-button-toggle value="SIGNED"
      ><span class="status-tag-signed"></span
      ><span class="status-tag-name">{{
        "model.status-signed" | translate
      }}</span></mat-button-toggle
    >

    <mat-button-toggle value="PENDING"
      ><span class="status-tag-pending"></span
      ><span class="status-tag-name">{{
        "model.status-pending" | translate
      }}</span></mat-button-toggle
    >

    <mat-button-toggle value="REVOKED"
      ><span class="status-tag-revoked"></span
      ><span class="status-tag-name">{{
        "model.status-revoked" | translate
      }}</span></mat-button-toggle
    >

    <mat-button-toggle value=""
      ><span class="status-tag-name">{{
        "model.status-all" | translate
      }}</span></mat-button-toggle
    >
  </mat-button-toggle-group>
</div>

<div class="models-grid" *ngIf="!showAllMode; else modelsList">
  <ng-container
    *ngFor="
      let modelConsent of filteredModelsData || modelsData;
      let i = index;
      trackBy: trackByModelConsent
    "
  >
    <app-model-tile
      *ngIf="modelConsent.model && i < 10"
      class="model"
      [class.model-hidden]="
        selectedModel && selectedModel.id !== modelConsent.model.id
      "
      [model]="modelConsent.model"
      [modelConsent]="modelConsent.modelConsent"
      (selectModel)="onSelectModel($event)"
    ></app-model-tile>
  </ng-container>
  <div
    class="show-all"
    (click)="onShowAll()"
    *ngIf="filteredModelsData ? filteredModelsData?.length : modelsData?.length"
  >
    <button mat-icon-button class="stroked-icon-button" color="primary">
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
  </div>
</div>

<ng-template #modelsList>
  <div class="models-list">
    <ng-container
      *ngFor="
        let modelData of filteredModelsData || modelsData;
        trackBy: trackByModelConsent
      "
    >
      <app-model-info
        *ngIf="modelData.model"
        class="model"
        [class.model-hidden]="
          selectedModel && selectedModel.id !== modelData.model.id
        "
        [model]="modelData.model"
        [modelConsent]="modelData.modelConsent"
        [assets]="modelData.assets"
        [project]="project"
        [cancelOption]="cancelOption"
        (selectModel)="onSelectModel($event)"
        (removeModelConsent)="onRemoveModelConsent($event)"
        (revokeModelConsent)="onRevokeModelConsent($event)"
        (unrevokeModelConsent)="onUnrevokeModelConsent($event)"
        (sendReminder)="onSendReminder($event)"
      ></app-model-info>
    </ng-container>
    <div class="models-list-footer">
      <button
        mat-icon-button
        class="stroked-icon-button"
        color="primary"
        (click)="onShowAll()"
      >
        <mat-icon>keyboard_arrow_up</mat-icon>
      </button>
    </div>
  </div>
</ng-template>
