<app-header [title]="'menu.model-release-forms' | translate"
  ><button mat-stroked-button color="primary" (click)="onAddForm()">
    <mat-icon>add</mat-icon
    >{{ "model-release-form.add-model-release-form" | translate }}
  </button></app-header
>

<mat-accordion>
  <mat-expansion-panel
    class="form-panel mat-elevation-z0"
    *ngFor="let formTemplate of forms$ | async; trackBy: trackByForm"
    (opened)="onOpenFormTemplate(formTemplate)"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ formTemplate.name }}
      </mat-panel-title>
      <mat-panel-description> </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent [formGroup]="form">
      <div class="form-panel-inputs">
        <mat-form-field subscriptSizing="dynamic">
          <mat-label>{{ "model-release-form.name" | translate }}</mat-label>
          <input matInput type="text" formControlName="name" />
        </mat-form-field>
        <mat-slide-toggle class="slide-toggle" formControlName="generic">{{
          "model-release-form.generic" | translate
        }}</mat-slide-toggle>
      </div>
      <mat-tab-group (selectedTabChange)="onTabChange($event)">
        <mat-tab
          *ngFor="let language of languageOptions"
          [label]="formTemplateLanguage[language]"
        >
          <ng-template matTabContent>
            <!-- <textarea
            class="form-text"
            [formControlName]="formTemplateLanguage[language]"
            >{{
              getVariantByLanguage(formTemplate, formTemplateLanguage[language])
                ?.text
            }}</textarea
          > -->
            <editor
              class="form-text"
              licenseKey="gpl"
              [init]="{
                base_url: '/tinymce',
                promotion: false,
                statusbar: false,
                suffix: '.min',
                plugins: ['image', 'editimage', 'link', 'table'],
                contextmenu: 'link image editimage table',
                image_advtab: true,
                height: 500
              }"
              [formControlName]="formTemplateLanguage[language]"
            ></editor>
          </ng-template>
        </mat-tab>
      </mat-tab-group>

      <mat-action-row>
        <button mat-button color="primary" (click)="onDeletForm(formTemplate)">
          {{ "base.delete" | translate }}
        </button>
        <button
          mat-button
          color="primary"
          (click)="onSaveVariants(formTemplate)"
        >
          {{ "base.save" | translate }}
        </button>
      </mat-action-row>
    </ng-template>
  </mat-expansion-panel>
</mat-accordion>
