<div class="model-select-menu-icons">
  <mat-icon class="mat-elevation-z3 model-select-menu-icons-drag" cdkDragHandle
    >drag_pan</mat-icon
  >
  <mat-icon
    class="mat-elevation-z3"
    (click)="onAutoTag()"
    [matTooltip]="'asset.auto-tag-models' | translate"
    >robot_2</mat-icon
  >
  <mat-icon class="mat-elevation-z3" (click)="onClose()">close</mat-icon>
</div>

<div
  class="model-select-menu mat-elevation-z3"
  (click)="$event.stopPropagation()"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
>
  <mat-form-field class="model-select-menu-search">
    <mat-label>{{ "search.search" | translate }}</mat-label>
    <input
      matInput
      type="text"
      [(ngModel)]="searchValue"
      (keyup)="onSearch()"
    />
    <button matSuffix mat-icon-button (click)="onSearch()">
      <mat-icon>search</mat-icon>
    </button>
  </mat-form-field>

  <ng-container
    *ngFor="
      let modelConsent of filteredModelConsents;
      trackBy: trackByModelConsent
    "
  >
    <div
      mat-menu-item
      class="select-model-item"
      (click)="onSelectModel(modelConsent.model)"
    >
      <div class="select-model-item-container">
        <mat-checkbox
          *ngIf="multiselect"
          class="example-margin"
          [checked]="selectedModels?.includes(modelConsent.model.id)"
          [indeterminate]="false"
        >
        </mat-checkbox>
        <app-model-tile
          class="select-model-item-image"
          [model]="modelConsent.model"
          [modelConsent]="modelConsent.modelConsent"
          size="zoomable"
        ></app-model-tile>
        <p class="select-model-item-name text-subtitle-1">
          {{ modelConsent.model | modelName }}
        </p>
      </div>
    </div>
  </ng-container>
</div>
