import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  inject,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MatButtonToggleChange,
  MatButtonToggleModule,
} from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  Asset,
  Model,
  ModelConsent,
  ModelConsentStatus,
  Project,
} from '@verify/shared-components/models';
import { ModelConsentService } from '../../../services/model-consent.service';
import { ModelTileComponent } from '../model-tile/model-tile.component';
import { ModelInfoComponent } from './model-info/model-info.component';

@Component({
  selector: 'app-model-list',
  standalone: true,
  imports: [
    CommonModule,
    ModelTileComponent,
    ModelInfoComponent,
    MatIconModule,
    MatButtonModule,
    MatButtonToggleModule,
    TranslateModule,
  ],
  templateUrl: './model-list.component.html',
  styleUrl: './model-list.component.scss',
})
export class ModelListComponent implements OnChanges {
  private modelConsentService = inject(ModelConsentService);
  private snackBar = inject(MatSnackBar);
  private translateService = inject(TranslateService);

  @Input({ required: true })
  modelsData: Array<{
    model?: Model;
    modelConsent: ModelConsent;
    assets?: Asset[];
  }>;
  @Input()
  assets?: Asset[];
  @Input()
  selectedModel: Model;
  @Input()
  showAllMode = false;
  @Input()
  project?: Project;
  @Input()
  cancelOption?: boolean;

  @Output()
  selectModel = new EventEmitter<Model>();
  @Output()
  removeModelConsent = new EventEmitter<ModelConsent>();

  selectedStatus = '';
  filteredModelsData: Array<{
    model?: Model;
    modelConsent: ModelConsent;
    assets?: Asset[];
  }>;
  statusCounts = { signed: 0, revoked: 0, pending: 0 };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['modelConsents']) {
      this.statusCounts = {
        signed: (this.modelsData || []).filter(
          (modelData) =>
            modelData.modelConsent.status === ModelConsentStatus.signed,
        ).length,
        revoked: (this.modelsData || []).filter(
          (modelData) =>
            modelData.modelConsent.status === ModelConsentStatus.revoked,
        ).length,
        pending: (this.modelsData || []).filter(
          (modelData) =>
            modelData.modelConsent.status === ModelConsentStatus.pending ||
            modelData.modelConsent.status === ModelConsentStatus.extendRequest,
        ).length,
      };
    }
  }

  onSelectModel(model: Model): void {
    if (!this.selectedModel) {
      this.showAllMode = false;
    }
    this.selectModel.emit(model);
  }

  onRemoveModelConsent(modelConsent: ModelConsent): void {
    this.removeModelConsent.emit(modelConsent);
  }

  onRevokeModelConsent(modelConsent: ModelConsent): void {
    this.modelConsentService.revokeModelConsent(modelConsent);
  }

  onUnrevokeModelConsent(modelConsent: ModelConsent): void {
    this.modelConsentService.unrevokeModelConsent(modelConsent);
  }

  onSendReminder(modelConsent: ModelConsent): void {
    this.modelConsentService.resendEmail(modelConsent).subscribe(() => {
      this.snackBar.open(
        this.translateService.instant('model.reminder-sent'),
        null,
        { duration: 5000 },
      );
    });
  }

  onShowAll(): void {
    this.showAllMode = !this.showAllMode;
  }

  onChangeStatus(event: MatButtonToggleChange): void {
    this.filteredModelsData = event.value
      ? this.modelsData.filter(
          (modelData) => modelData.modelConsent.status === event.value,
        )
      : null;
  }

  trackByModelConsent(
    _: number,
    modelConsent: { modelConsent: ModelConsent },
  ): string {
    return modelConsent.modelConsent.modelId;
  }
}
