<dts-select-container
  #container="dts-select-container"
  [custom]="true"
  [selectWithShortcut]="true"
  [selectedItems]="selectedAssets"
  (select)="onSetSelection($event)"
>
  <div class="assets-header">
    <div class="assets-header-title">
      <h6>{{ "asset.assets" | translate }}</h6>
      <p class="text-body-2">
        {{ filteredAssets?.length }} {{ "base.total" | translate }} -
        {{ statusCounts?.signed }}
        {{ "model.status-signed" | translate }} -
        {{ statusCounts?.pending }}
        {{ "model.status-pending" | translate }} -
        {{ statusCounts?.revoked }}
        {{ "model.status-revoked" | translate }}
      </p>
    </div>
    <div class="assets-header-filters">
      <mat-chip-set>
        <mat-chip *ngFor="let filter of filters">
          <ng-container [ngSwitch]="filter.type">
            <ng-container *ngSwitchCase="'model'">{{
              filter.value | modelName
            }}</ng-container>
            <ng-container *ngSwitchCase="'status'">{{
              "model.status-" + filter.value.toLowerCase() | translate
            }}</ng-container>
            <ng-container *ngSwitchCase="'no-exports'">{{
              "asset.not-exported-assets" | translate
            }}</ng-container>
            <ng-container *ngSwitchCase="'no-models'">{{
              "asset.untagged-assets" | translate
            }}</ng-container>
          </ng-container>
          <button matChipRemove (click)="onRemoveFilter(filter.type)">
            <mat-icon>cancel</mat-icon>
          </button></mat-chip
        >
      </mat-chip-set>
      <div class="assets-header-filters-sort">
        <button
          mat-stroked-button
          color="primary"
          [matMenuTriggerFor]="orderMenu"
        >
          <mat-icon>sort</mat-icon>
          {{ orderByColumn.translation | translate }}
        </button>
        <button
          class="stroked-icon-button-square"
          mat-stroked-button
          color="primary"
          (click)="onOrderByDirection()"
        >
          <mat-icon>{{
            orderBy.direction === "asc"
              ? "keyboard_arrow_down"
              : "keyboard_arrow_up"
          }}</mat-icon>
        </button>
      </div>
      <button
        class="stroked-icon-button-square"
        mat-stroked-button
        color="primary"
        [matMenuTriggerFor]="filterMenu"
      >
        <mat-icon>filter_alt</mat-icon>
      </button>
    </div>
  </div>

  <div class="assets">
    <app-asset-tile
      *ngFor="let asset of filteredAssets; trackBy: trackByAsset"
      [asset]="asset"
      [selected]="selectedAssets.includes(asset.id)"
      [selectable]="true"
      [dtsSelectItem]="asset.id"
      (openAsset)="onOpenAsset($event)"
      (selectAsset)="onSelectAsset($event.id)"
    ></app-asset-tile>
  </div>
</dts-select-container>

<mat-menu #orderMenu="matMenu">
  <button
    mat-menu-item
    *ngFor="let column of sortableColumns"
    (click)="onOrderBy(column.field)"
  >
    {{ column.translation | translate }}
  </button>
</mat-menu>

<mat-menu #filterMenu="matMenu">
  <button
    mat-menu-item
    (click)="onAddFilter('status', ModelConsentStatus.signed)"
  >
    <span class="status-filter-signed"></span
    >{{ "model.status-signed" | translate }}
  </button>
  <button
    mat-menu-item
    (click)="onAddFilter('status', ModelConsentStatus.pending)"
  >
    <span class="status-filter-pending"></span
    >{{ "model.status-pending" | translate }}
  </button>
  <button
    mat-menu-item
    (click)="onAddFilter('status', ModelConsentStatus.revoked)"
  >
    <span class="status-filter-revoked"></span
    >{{ "model.status-revoked" | translate }}
  </button>
  <button mat-menu-item (click)="onAddFilter('no-exports', null)">
    <mat-icon>ios_share</mat-icon>
    {{ "asset.not-exported-assets" | translate }}
  </button>
  <button mat-menu-item (click)="onAddFilter('no-models', null)">
    <mat-icon>location_home</mat-icon>
    {{ "asset.untagged-assets" | translate }}
  </button>
</mat-menu>
