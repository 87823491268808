import { from, map, Observable, of, switchMap } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { documentId, where } from '@angular/fire/firestore';
import { UploadTask } from '@angular/fire/storage';
import {
  CollectionName,
  FunctionName,
  StoragePath,
} from '@verify/shared-components/helpers';
import {
  ConfirmConsentRequest,
  EmailInviteRequest,
  GetModelConsentFormRequest,
  GetModelConsentFormResponse,
  GetModelRegistrationInfoRequest,
  GetModelRegistrationInfoRequestFromInvite,
  GetModelRegistrationInfoResponse,
  IndexName,
  Model,
  RevokeConsentRequest,
  SaveModelRegistrationRequest,
  SearchRequest,
  SearchResponse,
} from '@verify/shared-components/models';
import {
  AuthService,
  FirestoreService,
  FunctionService,
  StorageService,
} from '@verify/shared-components/services';

@Injectable({
  providedIn: 'root',
})
export class ModelService {
  private firestore = inject(FirestoreService);
  private functionService = inject(FunctionService);
  private storageService = inject(StorageService);
  private authService = inject(AuthService);

  constructor() {}

  getModel(modelId: string): Observable<Model | undefined> {
    return this.firestore.getDocument<Model>(
      `${CollectionName.models}/${modelId}`,
    );
  }

  getModels(): Observable<Model[]> {
    return this.firestore.getCollection<Model>(CollectionName.models);
  }

  getModelsById(modelIds: string[]): Observable<Model[]> {
    return this.firestore.getQuery<Model>(
      CollectionName.models,
      where(documentId(), 'in', modelIds),
    );
  }

  getModelsByProject(projectId: string): Observable<Model[]> {
    return this.firestore.getQuery<Model>(
      CollectionName.models,
      where('projectIds', 'array-contains', projectId),
    );
  }

  searchModels(queryString: string): Observable<Model[]> {
    return this.functionService
      .call<SearchRequest, SearchResponse<Model>>(FunctionName.elasticSearch, {
        index: IndexName.model,
        query: {
          queryString,
          searchFields: ['firstName', 'lastName', 'email'],
          nested: { path: 'customData', searchFields: ['customData.value'] },
        },
        from: 0,
        size: 50,
        orderBys: [{ field: '_score' }],
      })
      .pipe(
        switchMap((response) =>
          response.hits?.length
            ? this.getModelsById(response.hits.map((hit) => hit.id)).pipe(
                map((models) =>
                  response.hits.map((hit) =>
                    models.find((model) => model.id === hit.id),
                  ),
                ),
              )
            : of([]),
        ),
      );
  }

  inviteModelsByEmail(
    projectId: string,
    formTemplateId: string,
    formVariantId: string,
    emails: string[],
  ): Observable<void> {
    return this.functionService.call<EmailInviteRequest, void>(
      FunctionName.modelEmailInvite,
      {
        tenantId: this.authService.tenantId,
        projectId,
        formTemplateId,
        formVariantId,
        emails,
      },
    );
  }

  getModelRegistrationInfo(
    tenantId: string,
    projectId: string,
    formTemplateId: string,
  ): Observable<GetModelRegistrationInfoResponse> {
    return this.functionService.call<
      GetModelRegistrationInfoRequest,
      GetModelRegistrationInfoResponse
    >(FunctionName.getModelRegistrationInfo, {
      tenantId,
      projectId,
      formTemplateId,
    });
  }

  getModelRegistrationInfoFromInvite(
    tenantId: string,
    modelConsentId: string,
  ): Observable<GetModelRegistrationInfoResponse> {
    return this.functionService.call<
      GetModelRegistrationInfoRequestFromInvite,
      GetModelRegistrationInfoResponse
    >(FunctionName.getModelRegistrationInfo, {
      tenantId,
      modelConsentId,
    });
  }

  saveModelRegistration(
    request: SaveModelRegistrationRequest,
    selfie: File,
  ): Observable<boolean> {
    const response$ = this.functionService.call<
      SaveModelRegistrationRequest,
      string
    >(FunctionName.saveModelRegistration, request);

    return response$.pipe(
      switchMap((modelId) =>
        from(
          new Promise<boolean>((resolve, reject) => {
            this.uploadSelfie(selfie, modelId).on('state_changed', {
              error: () => reject(false),
              complete: () => resolve(true),
            });
          }),
        ),
      ),
    );
  }

  generatePDF(request: SaveModelRegistrationRequest): Observable<void> {
    return this.functionService.call<SaveModelRegistrationRequest, void>(
      FunctionName.generatePDF,
      request,
    );
  }

  getModelConsentForm(
    tenantId: string,
    modelConsentId: string,
  ): Observable<GetModelConsentFormResponse> {
    return this.functionService.call<
      GetModelConsentFormRequest,
      GetModelConsentFormResponse
    >(FunctionName.getModelConsentFormInfo, {
      tenantId,
      modelConsentId,
    });
  }

  confirmConsent(request: ConfirmConsentRequest): Observable<boolean> {
    return this.functionService.call<ConfirmConsentRequest, boolean>(
      FunctionName.confirmModelRegistration,
      request,
    );
  }

  revokeConsent(request: RevokeConsentRequest): Observable<boolean> {
    return this.functionService.call<RevokeConsentRequest, boolean>(
      FunctionName.revokeConsent,
      request,
    );
  }

  private uploadSelfie(file: File, modelId: string): UploadTask {
    return this.storageService.uploadFile(
      `${StoragePath.models}/${modelId}/${file.name}`,
      file,
      modelId,
    );
  }
}
