import {
  catchError,
  distinctUntilKeyChanged,
  map,
  Observable,
  of,
  Subject,
  takeUntil,
} from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  inject,
  OnDestroy,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import {
  ModelConsentStatus,
  Project,
  SearchRequestOrderBy,
  Tenant,
  User,
  UserRole,
} from '@verify/shared-components/models';

import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatSortModule, Sort } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { SpinnerComponent } from '@verify/shared-components/components';
import { UserNamePipe } from '@verify/shared-components/helpers';
import {
  AuthService,
  DialogService,
  HasRolePipe,
  TimestampPipe,
} from '@verify/shared-components/services';
import { AssetService, UserService } from '../../services';
import { ModelConsentService } from '../../services/model-consent.service';
import { ProjectService } from '../../services/project.service';
import { HeaderComponent } from '../shared/header/header.component';
import { EditProjectDialogComponent } from './edit-project-dialog/edit-project-dialog.component';
import { ProjectTileComponent } from './project-tile/project-tile.component';

@Component({
  selector: 'app-projects',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    ProjectTileComponent,
    HasRolePipe,
    MatIconModule,
    TranslateModule,
    HeaderComponent,
    RouterModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    TimestampPipe,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    SpinnerComponent,
    MatMenuModule,
    MatCheckboxModule,
    MatChipsModule,
    UserNamePipe,
  ],
  templateUrl: './projects.component.html',
  styleUrl: './projects.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class ProjectsComponent implements OnDestroy {
  private authService = inject(AuthService);
  private projectService = inject(ProjectService);
  private assetService = inject(AssetService);
  private userService = inject(UserService);
  private modelConsentService = inject(ModelConsentService);
  private dialogService = inject(DialogService);
  private activatedRoute = inject(ActivatedRoute);
  private router = inject(Router);

  private destroy$ = new Subject<void>();

  projects$: Observable<Project[]>;
  projectsDatasource$: Observable<
    Array<{
      project: Project;
      createdBy$: Observable<User>;
      assetCount$: Observable<number>;
      taggedAssets$: Observable<number>;
      exportedAssets$: Observable<number>;
      taggedModels$: Observable<number>;
      pendingModels$: Observable<number>;
      revokedModels$: Observable<number>;
      assetMatches$: Observable<number>;
    }>
  >;

  UserRole = UserRole;
  viewMode: 'tile' | 'list' = 'tile';

  isSearching = false;
  searchValue = '';
  currentPage = 0;
  itemsPerPage = 10;
  totalItems = 0;
  orderBy: { field: string; direction: 'asc' | 'desc' };

  @ViewChild('searchInput')
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  searchInput: ElementRef<any>;

  displayedColumns: string[] = [
    'name',
    'models',
    'assets',
    'modificationDate',
    'expirationDate',
    'matches',
    'actions',
  ];

  availableColumns: Array<{
    name: string;
    translation: string;
    orderBy?: SearchRequestOrderBy;
  }> = [
    {
      name: 'name',
      translation: 'project.name',
      orderBy: { field: 'name.keyword' },
    },
    {
      name: 'description',
      translation: 'project.description',
      orderBy: { field: 'description.keyword' },
    },
    {
      name: 'createdBy',
      translation: 'project.created-by',
      orderBy: { field: 'createdBy.keyword' },
    },
    {
      name: 'enableRevoke',
      translation: 'project.enable-revoke',
      orderBy: { field: 'enableRevoke' },
    },
    {
      name: 'models',
      translation: 'model.models',
      orderBy: {
        field: 'modelConsents.weight',
        mode: 'sum',
        missing: 0,
        nested: { path: 'modelConsents' },
      },
    },
    {
      name: 'taggedModels',
      translation: 'model.tagged-models',
    },
    {
      name: 'pendingModels',
      translation: 'model.status-pending',
      orderBy: {
        field: 'modelConsents.weight',
        mode: 'sum',
        missing: '0',
        nested: {
          path: 'modelConsents',
          filter: {
            term: {
              ['modelConsents.status.keyword']: ModelConsentStatus.pending,
            },
          },
        },
      },
    },
    {
      name: 'revokedModels',
      translation: 'model.status-revoked',
      orderBy: {
        field: 'modelConsents.weight',
        mode: 'sum',
        missing: '0',
        nested: {
          path: 'modelConsents',
          filter: {
            term: {
              ['modelConsents.status.keyword']: ModelConsentStatus.revoked,
            },
          },
        },
      },
    },
    {
      name: 'assets',
      translation: 'asset.assets',
      orderBy: {
        field: 'assets.weight',
        mode: 'sum',
        missing: '0',
        nested: { path: 'assets' },
      },
    },
    {
      name: 'taggedAssets',
      translation: 'asset.tagged-assets',
      orderBy: {
        field: 'assets.weight',
        mode: 'sum',
        missing: '0',
        nested: {
          path: 'assets',
          filter: { range: { ['assets.modelTags']: { gt: 0 } } },
        },
      },
    },
    {
      name: 'exportedAssets',
      translation: 'asset.exported-assets',
      orderBy: {
        field: 'assets.weight',
        mode: 'sum',
        missing: '0',
        nested: {
          path: 'assets',
          filter: { range: { ['assets.exports']: { gt: 0 } } },
        },
      },
    },
    {
      name: 'modificationDate',
      translation: 'project.modification-date',
      orderBy: { field: 'modificationDate' },
    },
    {
      name: 'expirationDate',
      translation: 'project.expiration-date',
      orderBy: { field: 'expirationDate' },
    },
    {
      name: 'matches',
      translation: 'tracking.matches',
      orderBy: {
        field: 'assets.matches',
        mode: 'sum',
        missing: '0',
        nested: {
          path: 'assets',
        },
      },
    },
    ...(this.authService.tenant?.customProjectFields || []).map(
      (customField) => ({
        name: customField.name,
        translation: customField.name,
      }),
    ),
  ];

  constructor() {
    this.projects$ = this.projectService.getProjects();
    this.searchProjects();

    this.activatedRoute.queryParams
      .pipe(takeUntil(this.destroy$), distinctUntilKeyChanged('viewMode'))
      .subscribe(({ viewMode }) => {
        this.viewMode = viewMode || 'tile';
      });
  }

  get tenant(): Tenant {
    return this.authService.tenant;
  }

  getCustomValue(
    project: Project,
    fieldName: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): any {
    return project.customData?.find(
      (customField) => customField.name === fieldName,
    )?.value;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onAddProject(): void {
    this.dialogService.openDialog<Partial<Project>>(
      EditProjectDialogComponent,
      {
        width: this.dialogService.widths.medium,
      },
    );
  }

  onDeleteProject(project: Project): void {
    this.projectService.deleteProject(project.id);
  }

  onOpenProject(project: Project): void {
    this.router.navigate(['project', project.id]);
  }

  onSearchKeyUp(e: KeyboardEvent, searchValue: string): void {
    if (e.key === 'Enter') {
      this.onSearch(searchValue);
    }
  }

  onSearch(searchValue?: string): void {
    this.searchValue = searchValue;
    // if (!this.searchValue) {
    //   this.projects$ = this.projectService.getProjects();
    //   return;
    // }
    this.searchInput.nativeElement.value = '';
    this.searchProjects();
  }

  onSortChange(sortState: Sort): void {
    const column = this.availableColumns.find(
      (column) => column.name === sortState.active,
    );
    this.orderBy =
      column && sortState.direction
        ? { ...column.orderBy, direction: sortState.direction }
        : null;
    this.searchProjects();
  }

  onHandlePageEvent(e: PageEvent) {
    this.itemsPerPage = e.pageSize;
    this.currentPage = e.pageIndex;
    this.searchProjects();
  }

  onToggleColumn(column: string): void {
    this.displayedColumns = this.displayedColumns.includes(column)
      ? this.displayedColumns.filter((col) => col !== column)
      : this.availableColumns
          .filter(
            (col) =>
              this.displayedColumns.includes(col.name) || col.name === column,
          )
          .map((col) => col.name);
  }

  trackByProject(_: number, project: Project): string {
    return project.id;
  }

  private searchProjects(): void {
    this.isSearching = true;
    this.projectsDatasource$ = this.projectService
      .searchProjects({
        queryString: this.searchValue,
        from: this.currentPage * this.itemsPerPage,
        size: this.itemsPerPage,
        orderBy: this.orderBy,
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          this.isSearching = false;
          return of({ totalHits: 0, projects: [] });
        }),
        map((result) => {
          this.totalItems = result.totalHits;
          this.isSearching = false;

          return result.projects.map((project) => {
            const assets$ = this.assetService.getAssets({
              projectId: project.id,
            });
            const modelConsents$ =
              this.modelConsentService.getModelConsentsByProject(project.id);
            return {
              project,
              createdBy$: this.userService.getUser(project.createdBy),
              assetCount$: assets$.pipe(map((assets) => assets.length)),
              taggedAssets$: assets$.pipe(
                map(
                  (assets) =>
                    assets.filter((asset) => asset.modelTags?.length > 0)
                      .length,
                ),
              ),
              exportedAssets$: assets$.pipe(
                map(
                  (assets) =>
                    assets.filter((asset) => asset.exports?.length > 0).length,
                ),
              ),
              taggedModels$: assets$.pipe(
                map(
                  (assets) =>
                    assets.reduce(
                      (acc, asset) => [
                        ...acc,
                        ...(asset.modelIds || []).filter(
                          (modelId) => !acc.includes(modelId),
                        ),
                      ],
                      [],
                    ).length,
                ),
              ),
              pendingModels$: modelConsents$.pipe(
                map(
                  (modelConsents) =>
                    modelConsents.filter(
                      (modelConsent) =>
                        modelConsent.status === ModelConsentStatus.pending,
                    ).length,
                ),
              ),
              revokedModels$: modelConsents$.pipe(
                map(
                  (modelConsents) =>
                    modelConsents.filter(
                      (modelConsent) =>
                        modelConsent.status === ModelConsentStatus.revoked,
                    ).length,
                ),
              ),
              assetMatches$: assets$.pipe(
                map((assets) =>
                  assets.reduce(
                    (total, asset) => total + (asset.matches?.length || 0),
                    0,
                  ),
                ),
              ),
            };
          });
        }),
      );
  }
}
