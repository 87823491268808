import { Overlay, OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  ConfirmDialogComponent,
  SpinnerComponent,
} from '@verify/shared-components/components';
import {
  Asset,
  AssetFileSize,
  Model,
  ModelConsent,
  Project,
  UserRole,
} from '@verify/shared-components/models';
import {
  AuthService,
  DialogService,
  HasRolePipe,
  StorageService,
} from '@verify/shared-components/services';
import { filter, map, Observable, of } from 'rxjs';
import { AssetService } from '../../../services';
import { ModelSelectMenuComponent } from '../../shared/model-select-menu/model-select-menu.component';

@Component({
  selector: 'app-selection-bar',
  standalone: true,
  imports: [
    CommonModule,
    SpinnerComponent,
    HasRolePipe,
    ModelSelectMenuComponent,
    TranslateModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    OverlayModule,
  ],
  templateUrl: './selection-bar.component.html',
  styleUrl: './selection-bar.component.scss',
})
export class SelectionBarComponent implements OnChanges {
  private authService = inject(AuthService);
  private assetService = inject(AssetService);
  private storageService = inject(StorageService);
  private snackBar = inject(MatSnackBar);
  private translateService = inject(TranslateService);
  private dialogService = inject(DialogService);
  overlay = inject(Overlay);

  @Input({ required: true })
  project: Project;
  @Input({ required: true })
  assets: Asset[];
  @Input({ required: true })
  modelConsents?:
    | Array<{ model?: Model; modelConsent: ModelConsent }>
    | undefined;
  @Input()
  selectedAssets: string[];

  @Output()
  setSelection = new EventEmitter<string[]>();

  tenant$ = this.authService.tenant$;
  UserRole = UserRole;
  selectedModelTags?: string[];
  exporting = false;
  modelSelectOpen = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedAssets'] || changes['assets']) {
      this.loadModelTags();
    }
  }

  onDownloadSelected(): void {
    const filesToDownload = this.assets
      .filter(
        (asset) =>
          this.selectedAssets.includes(asset.id) &&
          asset.files?.some((file) => file.size === AssetFileSize.original),
      )
      .map(
        (asset) =>
          asset.files?.find((file) => file.size === AssetFileSize.original)
            ?.filePath,
      );

    this.storageService
      .downloadMultiple(filesToDownload, true)
      .subscribe((result) => console.log(result));
  }

  onExportSelectedToLytho(): void {
    this.filterSelectedAssetsForExport('LYTHO')
      .pipe(filter((assetIds) => assetIds?.length > 0))
      .subscribe((assetIds) => {
        this.exporting = true;
        this.assetService
          .exportAssetsToLytho(this.project.id, assetIds)
          .subscribe({
            next: (result) => {
              this.exporting = false;
              this.selectedAssets = [];
              this.snackBar
                .open(
                  this.translateService.instant('asset.export-success', {
                    count: result.assets.filter((res) => !!res.success).length,
                  }),
                  result.collectionUrl
                    ? this.translateService.instant('asset.view-collection')
                    : null,
                  { duration: 8000 },
                )
                .onAction()
                .subscribe(() => {
                  window.open(result.collectionUrl);
                });
            },
            error: (error) => {
              console.log(error);
              this.snackBar.open(
                this.translateService.instant('asset.export-error'),
                null,
                { duration: 8000 },
              );
              this.exporting = false;
            },
          });
      });
  }

  onExportSelectedToBynder(): void {
    this.filterSelectedAssetsForExport('BYNDER')
      .pipe(filter((assetIds) => assetIds?.length > 0))
      .subscribe((assetIds) => {
        this.exporting = true;
        this.assetService
          .exportAssetsToBynder(this.project.id, assetIds)
          .subscribe({
            next: (result) => {
              this.exporting = false;
              this.selectedAssets = [];
              this.snackBar
                .open(
                  this.translateService.instant('asset.export-success', {
                    count: result.assets.filter((res) => !!res.success).length,
                  }),
                  result.collectionUrl
                    ? this.translateService.instant('asset.view-collection')
                    : null,
                  { duration: 8000 },
                )
                .onAction()
                .subscribe(() => {
                  window.open(result.collectionUrl);
                });
            },
            error: (error) => {
              console.log(error);
              this.snackBar.open(
                this.translateService.instant('asset.export-error'),
                null,
                { duration: 8000 },
              );
              this.exporting = false;
            },
          });
      });
  }

  onTagModel(model: Model): void {
    this.selectedAssets.forEach((assetId) => {
      this.assetService.addModelTag(assetId, {
        creationDate: Timestamp.now(),
        modelId: model.id,
      });
    });
  }

  onUntagModel(model: Model): void {
    this.selectedAssets.forEach((assetId) => {
      this.assetService.removeModelTag(assetId, {
        creationDate: Timestamp.now(),
        modelId: model.id,
      });
    });
  }

  onDeleteSelected(): void {
    this.dialogService
      .openDialog<boolean>(ConfirmDialogComponent, {})
      .pipe(filter((confirmed) => !!confirmed))
      .subscribe(() => {
        this.selectedAssets.forEach((assetId) => {
          this.assetService.deleteAsset(assetId);
          this.onClearSelection();
        });
      });
  }

  onAutoTag(): void {
    this.assetService.autoTagAssets(this.project.id, this.selectedAssets);
  }

  onOpenModelSelect(): void {
    this.modelSelectOpen = true;
  }

  onCloseModelSelect(): void {
    this.modelSelectOpen = false;
  }

  onClearSelection(): void {
    this.setSelection.emit([]);
    //this.onCloseModelSelect();
  }

  trackByModelConsent(
    _: number,
    modelConsent: { modelConsent: ModelConsent },
  ): string {
    return modelConsent.modelConsent.modelId;
  }

  private loadModelTags(): void {
    const selectedAssets = (this.assets || []).filter((asset) =>
      this.selectedAssets.includes(asset.id),
    );
    this.selectedModelTags = !selectedAssets.length
      ? []
      : this.modelConsents
          .filter((modelConsent) =>
            selectedAssets.every((asset) =>
              asset.modelIds?.includes(modelConsent.model.id),
            ),
          )
          .map((modelConsent) => modelConsent.model.id);
  }

  private filterSelectedAssetsForExport(type: string): Observable<string[]> {
    return this.assets.some(
      (asset) =>
        this.selectedAssets.includes(asset.id) &&
        asset?.exports?.some((assetExport) => assetExport.type === type),
    )
      ? this.dialogService
          .openDialog<boolean>(ConfirmDialogComponent, {
            data: { text: 'asset.include-exported-assets' },
          })
          .pipe(
            map((includeExported) =>
              includeExported
                ? this.selectedAssets
                : this.assets
                    .filter(
                      (asset) =>
                        this.selectedAssets.includes(asset.id) &&
                        !asset?.exports?.some(
                          (assetExport) => assetExport.type === type,
                        ),
                    )
                    .map((asset) => asset.id),
            ),
          )
      : of(this.selectedAssets);
  }
}
