<app-header [title]="'user.users' | translate"
  ><button mat-stroked-button color="primary" (click)="onAddUser()">
    <mat-icon>add</mat-icon> {{ "user.add-user" | translate }}
  </button></app-header
>

<table mat-table [dataSource]="users$ | async" class="bordered-table">
  <ng-container matColumnDef="firstName">
    <th mat-header-cell *matHeaderCellDef>
      {{ "user.first-name" | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.firstName }}
    </td>
  </ng-container>

  <ng-container matColumnDef="lastName">
    <th mat-header-cell *matHeaderCellDef>
      {{ "user.last-name" | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.lastName }}
    </td>
  </ng-container>

  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef>{{ "user.email" | translate }}</th>
    <td mat-cell *matCellDef="let element">
      <div class="flex-row">
        {{ element.email }}
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="roles">
    <th mat-header-cell *matHeaderCellDef>{{ "user.roles" | translate }}</th>
    <td mat-cell *matCellDef="let element">
      {{ element.roles?.join(", ") }}
    </td>
  </ng-container>

  <ng-container matColumnDef="created">
    <th mat-header-cell *matHeaderCellDef>
      {{ "user.creation-date" | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{
        element.creationDate
          ? (element.creationDate.toDate() | timestamp: "mediumDate")
          : ""
      }}
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button mat-button (click)="onEditUser(element)">
        {{ "base.edit" | translate }}
      </button>
      <button mat-button (click)="onDeleteUser(element)">
        {{ "base.delete" | translate }}
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
