import { Injectable, inject } from '@angular/core';
import {
  Timestamp,
  and,
  documentId,
  or,
  orderBy,
  where,
} from '@angular/fire/firestore';
import { UploadTask } from '@angular/fire/storage';
import {
  CollectionName,
  FunctionName,
  StoragePath,
} from '@verify/shared-components/helpers';
import {
  ClientTimestamp,
  DeleteItemsRequest,
  ExtendProjectRequest,
  IndexName,
  Project,
  ProjectStatus,
  SearchRequest,
  SearchRequestOrderBy,
  SearchResponse,
  UserRole,
} from '@verify/shared-components/models';
import {
  AuthService,
  FirestoreService,
  FunctionService,
  StorageService,
} from '@verify/shared-components/services';
import { Observable, from, map, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  private firestore = inject(FirestoreService);
  private storageService = inject(StorageService);
  private authService = inject(AuthService);
  private functionService = inject(FunctionService);

  constructor() {}

  getProject(projectId: string): Observable<Project | undefined> {
    return this.firestore.getDocument<Project>(
      `${CollectionName.projects}/${projectId}`,
    );
  }

  getProjects(): Observable<Project[]> {
    const isProjectAdmin = this.authService.currentUser.roles.includes(
      UserRole.projectAdmin,
    );
    return !isProjectAdmin
      ? this.firestore.getQuery<Project>(
          CollectionName.projects,
          and(
            where('deleted', '!=', true),
            or(
              where(
                'assignees',
                'array-contains',
                this.authService.currentUser.id,
              ),
              where('createdBy', '==', this.authService.currentUser.id),
            ),
          ),
          orderBy('modificationDate', 'desc'),
        )
      : this.firestore.getQuery<Project>(
          CollectionName.projects,
          where('deleted', '!=', true),
          orderBy('modificationDate', 'desc'),
        );
  }

  getProjectsById(projectIds: string[]): Observable<Project[]> {
    return this.firestore.getQuery<Project>(
      CollectionName.projects,
      where(documentId(), 'in', projectIds),
    );
  }

  searchProjects({
    queryString,
    from,
    size,
    orderBy,
  }: {
    queryString: string;
    from?: number;
    size?: number;
    orderBy?: SearchRequestOrderBy;
  }): Observable<{ totalHits: number; projects: Project[] }> {
    return this.functionService
      .call<SearchRequest, SearchResponse<Project>>(
        FunctionName.elasticSearch,
        {
          index: IndexName.project,
          query: {
            queryString,
            searchFields: ['name', 'description', 'instructions'],
            nested: { path: 'customData', searchFields: ['customData.value'] },
          },
          from,
          size,
          orderBys: orderBy ? [orderBy] : [],
          filterDeleted: true,
        },
      )
      .pipe(
        switchMap((response) =>
          response.hits?.length
            ? this.getProjectsById(response.hits.map((hit) => hit.id)).pipe(
                map((projects) => ({
                  totalHits: response.totalHits,
                  projects: response.hits.map((hit) =>
                    projects.find((project) => project.id === hit.id),
                  ),
                })),
              )
            : of({ totalHits: 0, projects: [] }),
        ),
      );
  }

  updateProject(project: Project, banner?: File): Observable<boolean> {
    const response$ = from(
      this.firestore.updateDocument<Project>(
        `${CollectionName.projects}/${project.id}`,
        { ...project, modificationDate: Timestamp.now() },
      ),
    );

    return banner
      ? response$.pipe(
          switchMap(() =>
            from(
              new Promise<boolean>((resolve, reject) => {
                this.uploadBanner(banner, project.id).on('state_changed', {
                  error: () => reject(false),
                  complete: () => resolve(true),
                });
              }),
            ),
          ),
        )
      : of(true);
  }

  addProject(project: Partial<Project>, banner?: File): Observable<boolean> {
    const response$ = this.firestore.addDocument<Project>(
      CollectionName.projects,
      {
        ...project,
        status: ProjectStatus.open,
        createdBy: this.authService.currentUser.id,
        creationDate: Timestamp.now(),
        modificationDate: Timestamp.now(),
        deleted: false,
        online: false,
      },
    );

    return banner
      ? response$.pipe(
          switchMap((project) =>
            from(
              new Promise<boolean>((resolve, reject) => {
                this.uploadBanner(banner, project.id).on('state_changed', {
                  error: () => reject(false),
                  complete: () => resolve(true),
                });
              }),
            ),
          ),
        )
      : of(true);
  }

  deleteProject(projectId: string): void {
    this.firestore.updateDocument<Project>(
      `${CollectionName.projects}/${projectId}`,
      {
        deleted: true,
        modificationDate: Timestamp.now(),
      },
    );
  }

  undoDeleteProject(projectId: string): void {
    this.firestore.updateDocument<Project>(
      `${CollectionName.projects}/${projectId}`,
      {
        deleted: false,
        modificationDate: Timestamp.now(),
      },
    );
  }

  permanentlyDeleteProjects(projectIds: string[]): Observable<void> {
    return this.functionService.call<DeleteItemsRequest, void>(
      FunctionName.deleteItems,
      { itemIds: projectIds, type: 'projects' },
      { timeout: 600000 },
    );
  }

  getDeletedProjects(): Observable<Project[]> {
    const filters = [
      where('deleted', '==', true),
      orderBy('modificationDate', 'desc'),
    ];
    return this.firestore.getQuery<Project>(
      CollectionName.projects,
      ...filters,
    );
  }

  extendProject(
    projectId: string,
    description: string,
    expirationDate: ClientTimestamp,
  ): Observable<void> {
    return this.functionService.call<ExtendProjectRequest, void>(
      FunctionName.extendProject,
      {
        tenantId: this.authService.tenantId,
        projectId,
        description,
        expirationDate,
      },
    );
  }

  private uploadBanner(file: File, projectId: string): UploadTask {
    return this.storageService.uploadFile(
      `${StoragePath.projects}/${projectId}/${StoragePath.banner}/${file.name}`,
      file,
      projectId,
    );
  }
}
