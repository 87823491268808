import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UserNamePipe } from '@verify/shared-components/helpers';
import {
  AssetFileSize,
  Notification,
  User,
  UserRole,
} from '@verify/shared-components/models';
import {
  AuthService,
  HasRolePipe,
  StorageService,
} from '@verify/shared-components/services';
import { EMPTY, Observable, of, switchMap, take } from 'rxjs';
import { NotificationService } from '../../services';
import { NotificationComponent } from './notification/notification.component';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    HasRolePipe,
    MatSidenavModule,
    UserNamePipe,
    MatBadgeModule,
    FormsModule,
    OverlayModule,
    NotificationComponent,
    TranslateModule,
  ],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class LayoutComponent implements OnInit {
  private authService = inject(AuthService);
  private router = inject(Router);
  private storageService = inject(StorageService);
  private notificationService = inject(NotificationService);
  private translateService = inject(TranslateService);

  UserRole = UserRole;
  logoUrl$?: Observable<string>;
  selfieUrl$?: Observable<string>;
  notificationCount$: Observable<number>;
  notifications$: Observable<Notification[]>;
  notificationsOpen = false;
  searchToggle = false;

  searchValue = '';

  ngOnInit(): void {
    this.logoUrl$ = this.authService.tenant$.pipe(
      switchMap((tenant) => {
        const file =
          tenant.logo?.find((file) => file.size === AssetFileSize.size300) ||
          tenant.logo?.[0];
        if (file) {
          return this.storageService.getDownloadUrl(file.filePath, true);
        } else return EMPTY;
      }),
    );

    this.selfieUrl$ = this.authService.currentUser$.pipe(
      switchMap((currentUser) => {
        if (
          currentUser.language &&
          this.translateService.currentLang !== currentUser.language
        ) {
          this.translateService.use(currentUser.language.toLocaleLowerCase());
        }
        this.notificationCount$ =
          this.notificationService.getNotificationCounter();
        this.notifications$ = this.notificationService.getNotifications();

        const file =
          currentUser.selfie?.find(
            (file) => file.size === AssetFileSize.size100,
          ) || this.user.selfie?.[0];
        if (file) {
          return this.storageService.getDownloadUrl(file.filePath, true);
        } else return of(null);
      }),
    );
  }

  get user(): User {
    return this.authService.currentUser;
  }

  get initials(): string {
    if (this.user) {
      const { firstName, lastName, email } = this.user;
      if (firstName || lastName) {
        return `${firstName?.substring(0, 1)}${lastName?.substring(0, 1)}`;
      } else {
        return email.substring(0, 1);
      }
    } else {
      return '';
    }
  }

  onLogout(): void {
    this.authService
      .getAuth()
      .signOut()
      .then(() => {
        this.router.navigateByUrl('/login');
      });
  }

  onSearchKeyUp(e: KeyboardEvent): void {
    if (e.key === 'Enter') {
      this.onSearch();
    }
  }

  onSearch(): void {
    this.router.navigate(['search'], {
      queryParams: { query: this.searchValue },
    });
  }

  onToggleNotifications(): void {
    this.notificationsOpen = !this.notificationsOpen;
    if (this.notificationsOpen) {
      this.notifications$.pipe(take(1)).subscribe((notifications) => {
        const unreadNotifications = notifications.filter(
          (notification) => !notification.read,
        );
        if (unreadNotifications.length > 0) {
          this.notificationService.markNotificationsAsRead(
            unreadNotifications.map((notification) => notification.id),
          );
        }
      });
    }
  }

  onToggleSearch(): void {
    this.searchToggle = !this.searchToggle;
  }

  trackByNotification(_: number, notification: Notification): string {
    return notification.id;
  }
}
